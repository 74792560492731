/* This file is used to define two functions called when a user is LOGGING IN and SIGNING UP 
hence this isnt a component but a function */

// This function is called when a user is loggedin. It saves the user's firstname(username) in the local storage
export const getLoggedIn = username => {
    localStorage.setItem('currentLoggedinUser', username)  //i will save the first name in the LS
    window.location = window.location.href   //this line refreshes the page to save url
}

/* When the user logs out, we want to clear the local storage*/
export const getLoggedOut = () => {
    localStorage.clear()       //clear your local storage
    window.location.href= '/'  // this line refreshes the page to save url
}