// import components needed for use on this page
import validate from './validator'


// import methods to be used from packages installed
import { useContext, useEffect, useState } from 'react'
import { getLoggedIn } from './utilities'
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CheckoutSteps from './CheckoutSteps';
import { Store } from '../Store';
import { getError } from '../utils';


const Login = (props) => {

    const navigate = useNavigate();

    // state which takes care of email and password
    const [formdata, setFormData] = useState({
        email: '',
        password: ''
    })

    const { search } = useLocation();
    const redirectInUrl = new URLSearchParams(search).get('redirect');
    const redirect = redirectInUrl ? redirectInUrl : '';


    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { userInfo } = state;


    //handle the change
    const handleChange = ce => {
        //make a copy of the data
        const copy_state = { ...formdata }
        // manipulate the copy
        copy_state[ce.target.id] = ce.target.value
        // update the actual formdata using the copy
        setFormData(copy_state)
    }

    const login = async levent => {
        levent.preventDefault()
        //reading the data from the state
        //validate the data
        const { validationStatus, validationMessage } = validate(formdata)

        if (validationStatus === true) {
            try {
                const response = await fetch('https://choptyme-be.onrender.com/user/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formdata)
                })
                const data = await response.json()

                if (data && response.status === 200) {
                    toast.success('Login successful!')

                    setTimeout(() => {
                        getLoggedIn(data.firstname)
                    }, 1000);

                    localStorage.setItem('LoggedinUserID', data.userid)  //i will save the first name in the LS

                    ctxDispatch({ type: 'USER_SIGNIN', payload: data });
                    localStorage.setItem('userInfo', JSON.stringify(data));
                    navigate(redirect || '/');
                }
                else {
                    toast.error('Invalid email or password')
                }
            }
            catch (error) {
                toast.error(getError(error))
            }
        }
        else {
            toast.error(validationMessage)
        }
    }

    useEffect(() => {
        if (userInfo) {
            return;
        }
    }, [navigate, redirect, userInfo]);


    return (
        <>
            <div className='container'>

                {userInfo ? <CheckoutSteps step1></CheckoutSteps> : ''}

                <Form onSubmit={login}>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="email" className="form-label">Email address</Form.Label>
                        <Form.Control type="email"
                            id="email"
                            value={formdata.email}
                            onChange={handleChange}
                            placeholder="Enter your email id"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                            value={formdata.password}
                            onChange={handleChange}
                            placeholder="Enter your password"
                            id="password"
                        />
                    </Form.Group>

                    <Button type="submit" className="btn btn-primary">Login</Button>
                    <Link style={{ color: 'red', float: 'right', fontWeight: 'bold', cursor: 'pointer' }} to='/forgottenpassword'
                        onClick={() => window.reload()}
                    >Forgotten Password?</Link>

                    <div className="Path"></div>

                </Form>
                <div>
                    {/* <span className='ms-5'>Don't have an Account?<Link to={`/register`} style={{ color: 'darkred' }} > Create Account</Link></span> */}
                    <span className='text-dark  ms-5'>Don't have an Account?<span style={{ color: 'darkred', cursor: 'pointer' }} data-bs-toggle="modal"
                        data-bs-target="#OpencreateaccPg"> Create Account</span></span>
                </div>

            </div>
        </>

    )
}

export default Login