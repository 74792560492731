// import components needed for use on this page
import Modal from './Modal'
import Login from './Login'
import CreateAccount from './CreateAccount'
import { toast } from "react-toastify";

// import methods to be used from packages installed
import { useState, useEffect, useRef } from 'react'
import { getLoggedOut } from './utilities'
import { Link, useNavigate } from 'react-router-dom'
import useGeoLocation from '../Functions/useGeoLocation';

// import my css
import './Banner.css'

const Banner = (props) => {

  // useNavigate action
  const navigate = useNavigate()
   const location = useGeoLocation()

  const [locationsData, setLocationData] = useState([])
  const mySavedRestaurants = useRef([])
  const [suggestions, setSuggessions] = useState([])

  // get logginUser from LS
  const activeUser = localStorage.getItem('currentLoggedinUser')

  //fetch the locations from the db
  const getLocations = async () => {
    //  const res = await fetch(`http://localhost:8000/restaurants/locations`)
    const res = await fetch(`https://choptyme-be.onrender.com/locations`)
    const data = await res.json()
    // console.log('rec location',data);
    const formattedData = data.map(i => `${i.city} - ${i.locality}`)
    setLocationData(formattedData)
    //        console.log('Location Data is fetched and sent')
  }

  // This function causes it to only load once.
  useEffect(() => {
    getLocations()
  }, [])




  // implementation of GetRestaurantsBased on city and locality method
  const getRestaurantsBasedOnCityAndLocality = async (city, locality) => {
    // make a call to the api to find restaurants with city and locality
    // console.log({'city': city, 'locality': locality})
    const res = await fetch(`https://choptyme-be.onrender.com/restaurant?city=${city}&locality=${locality}`)
    const localRestaurants = await res.json()
    // console.log('receivedRes', localRestaurants)
    // will save the result for suggestions
    mySavedRestaurants.current = localRestaurants
  }

  //invoked when location dropdown value changes
  const updateLocation = ce => {
    const newLocation = ce.target.value
    if (newLocation.indexOf('-') > -1) {
      const city = newLocation.split('-')[0].trim()
      const locality = newLocation.split('-')[1].trim()

      // Save these values in the Local Storage
      localStorage.setItem('currentCity', city)
      localStorage.setItem('locality', locality)

      // Get restaurants  belonging to given city and address
      getRestaurantsBasedOnCityAndLocality(city, locality)
    }
  }

  // APPLY SUGGESSTIONS => filter from saved reference
  const applySuggestions = ce => {
    if (ce.target.value) {
      const typedInput = ce.target.value.toLowerCase()
      // console.log(typedInput);
      // apply when atleast 3 char are typed
      if (typedInput.length > 2) {
        const suggestedRestaurants = mySavedRestaurants.current
        // filter restaurants based on what user has typed
        const filteredResForSuggestion = suggestedRestaurants.filter(i => i.name.toLowerCase().indexOf(typedInput) > -1)
        // console.log(filteredResForSuggestion)

        //set my suggestions
        if (filteredResForSuggestion.length > 0) {
          setSuggessions(filteredResForSuggestion)
        } else {
          setSuggessions([{
            name: 'Restaurant Not found!',
            locality: '',
            thumbnail: ``
          }])
        }
      }
      if (suggestions.length === 0 || typedInput.length < 3) {
        setSuggessions([{
          name: 'Keep typing!',
          locality: '',
          thumbnail: ``
        }])
      }
    }
  }

  // console.log('sug comingIN',suggestions)

  // FOR LOCATION DETECTION
  useEffect(() => {
      location.loaded ? JSON.stringify(location) : toast.error("Location data not available yet")
  },[location] )

  return (
    <>
      <div className="newbanner">
        <div className="banner__accounts">
          {activeUser ? (
            <>
                <button type="button" className="btn btn-outline-light dropdown-toggle float-end createacc" data-bs-toggle="dropdown"> Hello! {activeUser}</button>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to='/profile'>User Profile</Link></li>
                  <li><Link className="dropdown-item" to="/orderhistory">Order History</Link></li>
                  <li className="dropdown-divider" ></li>
                </ul>&nbsp;
                <button className='btn btn-outline-light login' onClick={getLoggedOut}>Logout</button>

            </>
          ) : (
            <>
              <button
                className="btn btn-outline-light"
                data-bs-toggle="modal"
                data-bs-target="#OpenloginPg"
              >
                Login
              </button>{" "}
              &nbsp;
              <button
                className="btn btn-outline-light"
                data-bs-toggle="modal"
                data-bs-target="#OpencreateaccPg"
              >
                Create an account
              </button>
            </>
          )}
          </div>
        <div>
          <img className="banner__logo" src="Assets/images/logo.png" alt="" />
        </div>
        <div className="banner__text mx-auto">
          <p>Find the best restaurants, cafés, and bars</p>
        </div>
        <div className="banner__form mx-auto">
          <datalist id="locationsData">
            {locationsData.map((i, index) => (
              <option key={index}>{i}</option>
            ))}
          </datalist>
          <datalist id="restaurantsData"> </datalist>
          <input
            type="text"
            id="tbLocations"
            list="locationsData"
            autoComplete="off"
            onChange={updateLocation}
            placeholder="Please type a location"
            className="banner__form__left"
          />
          <div>

          <input
            type="text" id="tbRestaurants" list="restaurantsData" autoComplete="off"
            onChange={applySuggestions}
            placeholder="Search for restaurants"
            className="banner__form__right"
          />
          <ul className="suggestionsBox">
            {suggestions.map((s,index) => (
              <li key={s.id || index} className="suggestionItem">
                <div className="suggestionImage">
                  <img
                    style={{
                      maxwidth: "40px",
                      maxHeight: "40px",
                      borderRadius: "50%",
                    }}
                    src={s.thumb}
                    alt="resLogo"
                  />
                </div>
                <div className="suggestionText">
                  <div className="suggestionTextName">{s.name}</div>
                  <div className="suggestionTextLocality">
                    {s.city} - {s.locality}
                  </div>
                </div>
                <div
                  className="orderButton"
                  onClick={(e) => navigate(`/restaurant/${s._id}`)}
                >
                  {s.name === "Restaurant Not found!" ? "" : s.name === 'Keep typing!' ? '' : "Order Now"}
                </div>
              </li>
            ))}
          </ul>
          </div>
        </div>
      </div>


      <Modal modalid="OpenloginPg" modaltitle="Login">
        <Login />
      </Modal>

      <Modal modalid="OpencreateaccPg" modaltitle="Create Account">
        <CreateAccount />
      </Modal>


      <div className='btmspace'></div>


    </>
  );
}

export default Banner;
//<div className="row banner"></div>