import React, { useContext, useEffect, useReducer } from 'react'
import { getError } from '../utils';
import { Store } from '../Store';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar'
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';


const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return { ...state, orders: action.payload, loading: false };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

 
const OrderHistory = () => {
    const { state } = useContext(Store);
    const { userInfo } = state;
    const navigate = useNavigate()

    const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
        loading: true,
        error: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'FETCH_REQUEST' });
            try {
                const { data } = await axios.get(`https://choptyme-be.onrender.com/orders/mine`,

                     { headers: { Authorization: `Bearer ${userInfo.token}` } }
                );
                dispatch({ type: 'FETCH_SUCCESS', payload: data });
            } catch (error) {
                dispatch({
                    type: 'FETCH_FAIL',
                    payload: getError(error),
                });
            }
        };
        fetchData();
    }, [userInfo]);


    return (
        <>
            <Navbar />

            <div className="container mt-3">
                <h2>My Orders</h2>
                <p>Manipulate your orders as you wish from here...</p>

                {loading ? (
                    <LoadingBox></LoadingBox>
                ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                ) : (



                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATE</th>
                                <th>TOTAL</th>
                                <th>PAID</th>
                                <th>DELIVERED</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {orders.map((order) => (
                                <tr key={order._id}>
                                    <td>{order._id}</td>
                                    <td>{order.createdAt.substring(0, 10)}</td>
                                    <td>{order.totalPrice.toFixed(2)}</td>
                                    <td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>
                                    <td>
                                        {order.isDelivered
                                            ? order.deliveredAt.substring(0, 10)
                                            : 'No'}
                                    </td>
                                    <td>
                                        <Button
                                            type="button"
                                            variant="light"
                                            onClick={() => {
                                                navigate(`/orders/order/${order._id}`);
                                            }}
                                        >
                                            Details
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>)}
                <div className='mt-5'>space here</div>
            </div>
            <Footer />
        </>
    )
}

export default OrderHistory