import './Pagination.css'

const Pagination = (props) => {

    // record when a link is clicked
    const handleChange = ce => {
        // const pageItems = document.querySelectorAll('.page-item')

        // console.log(ce.target.classList.remove('.active'))
        // gets the pgNumber
        const pageNumber = ce.target.innerText
        // get the state from Filterpage
        let copy_state = { ...props.filterData }  // where from this props.filterData

        if (pageNumber === 'Next') {
            copy_state.pg = copy_state.pg + 1
        }
        else if (pageNumber === 'Previous') {
            // code to ensure that page number doesn't run to negative value
            copy_state.pg = copy_state.pg - 1 < 1 ? 1
                : copy_state.pg - 1
        }

        else {
            copy_state.pg = Number(pageNumber)
        }
        //console.log(copy_state)
        props.setFilterData(copy_state)
    }


    const delOption = document.querySelectorAll('.page-item')

    const changeActiveItem = () => {
        delOption.forEach(item => {
            item.classList.remove('active')
        })
    }


    delOption.forEach(item => {
        item.addEventListener('click', () => {
            changeActiveItem()
            item.classList.add('active')
        })
    })




    return (
        <div className="col-md-4 offset-md-5">
            <nav aria-label="Page navigation">
                <ul className="pagination">
                    {props.filterData.pg === 1
                        ?
                        <li className="page-item"><button className="page-link previous text-muted" onClick={handleChange} disabled>Previous</button></li>
                        :
                        <li className="page-item"><button className="page-link previous" onClick={handleChange} >Previous</button></li>
                    }
                    <li className="page-item active"><button className="page-link " onClick={handleChange} >1</button></li>
                    <li className="page-item"><button className="page-link" onClick={handleChange} >2</button></li>
                    <li className="page-item"><button className="page-link" onClick={handleChange} >3</button></li>
                    <li className="page-item"><button className="page-link" onClick={handleChange} >Next</button></li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination