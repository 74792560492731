import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './FilterResults.css'

export const FilterResults = (props) => {

    const navigate = useNavigate()
    // saving the restaurants to stae
    const [restaurants, setRestaurants] = useState([])
    // create a query based on the filter object
    /////////////////////////////////////////////////////////////////////////////////THE QUERY////////////////////////////////////////////////                                  
    // implement getResbasedonQuery
    const getResbasedonQuery = async flo => {
        // backup my flo data
        const myFilteredObj = { ...flo }

        // Deletion of unwanted data, since it will be provided from UI
        delete myFilteredObj.pg
        delete myFilteredObj.order

        // look out for cuisine from the filteredObj and give it the right format
        if ('cuisines' in myFilteredObj) {
            myFilteredObj.cuisines = { "$in": myFilteredObj.cuisines }
        }
        //  console.log(myFilteredObj)
        //////////////////////////////////////////////////////////////////////////////USING THE FILTERED DATA TO QUERY THE API/////////////////////////////////////////
        const response = await fetch(`https://choptyme-fe.onrender.com/restaurants/filters/${flo.order}/${flo.pg}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(myFilteredObj)
        })
        const restaurants = await response.json()
        // WE NEED TO SEND ORDER(SORT) AND PAGE #
       // console.log('from filterResult', myFilteredObj)
        // console.log(restaurants)
        setRestaurants(restaurants) // setting state Restaurants witht the filteredData- restaurants.
    }

    ///////////////////////////////////////////////////////////////////CODE STARTS HERE//////////////////////////////////////////////////////
    // we will let this query to load only once using useEffect
    useEffect(() => {
        getResbasedonQuery(props.filterData)
    }, [props.filterData])


    return (

        <div className="col-md-9">
            {restaurants.map(i =>
                <div key={i._id} className="card mb-2">
                    <div className="row g-0" onClick={e => navigate(`/restaurant/${i._id}`)}>

                        <div className="col-md-4" >
                            <img src="../assets/images/FilterResult.png" className="img-fluid rounded-start" alt="..." />
                        </div>

                        <div className="col-md-8">
                            <div className="card-body">
                                {
                                    (i.name.length < 16)
                                        ? <h6 className="card-title heading">{i.name}</h6> : <p className='bighead'>{i.name} </p>
                                }
                                <p className="card-text subheading"> {i.locality}, {i.city} </p>
                            </div>
                        </div>
                    </div>

                    <div className="row" onClick={e => navigate(`/restaurant/${i._id}`)}>
                        <div className="col-md-12" >
                            <hr />
                            <p className="res-info">
                                <span className="text-muted"> Cuisines : {i.cuisines.join(', ')}</span><br />
                                <span className="text-muted"> Cost for two : GH&cent; {i.costfortwo}</span>
                            </p>
                        </div>
                    </div>

                </div>
            )}

        </div>
    )
}

export default FilterResults 