import React from "react";

const Carousel = (props) => {

    // const [file, setFile] = useState();

    // function handleChange(e) {
    //     console.log(e.target.files);
    //     setFile(URL.createObjectURL(props.images && props.images.map((i, index) => <div key={index}> {i}</div>)));
    //     return fileonClick={handleChange}
    // }

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <button className="fw-bold btn btn-light" 
                style={{ position: "absolute", zIndex: '100', top: '18rem', right: '6rem', color: 'darkblue' }}
            >Click to see Image Gallery</button>

            <div className="carousel-inner m-3 p-0">
                <img src='/assets/images/Banner/Banner1.png' alt='resImg' style={{ height: '400px', width: '100%' }} />

                {
                    props.images && props.images.map((i, index) => <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''} `}>
                        <img src={i} className="d-block w-100 " alt="..." style={{ height: '400px' }} />
                    </div>
                    )
                }

            </div>


            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}


export default Carousel