import React from 'react'

const Footer = () => {
  return (
      <div>
          <footer className='fixed-bottom' >
              <div className="footer h-23 clearfix text-white" style={{ "backgroundColor": '#34Ff23' }}></div>
              <nav className="navbar navbar-expand-sm bg-dark navbar-dark text-white d-flex justify-content-around">
                  <div>© Zomato_Clone All Rights Reserved </div>
                  <div>Powered by <a style={{ color: 'yellow' }} href="https://superjcybs.com"> &nbsp; SUPERJCYBS</a></div>
                  <span style={{ 'backgroundColor': 'white', color: 'blue', fontWeight: 'bold', paddingLeft: '10px', borderRadius: '50%', fontSize: '20px', width: '30px', height: '30px' }}><a href='https://www.facebook.com/superjcybs'>f</a></span>
              </nav>
          </footer>
    </div>
  )
}

export default Footer