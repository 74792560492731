// import my css
import './CreateAccount.css'

// import components needed for use on this page
import validate from './validator.js'
import { getLoggedIn } from './utilities'
import Modal from './Modal'
import Login from './Login'

// import methods to be used from packages installed
import { useState } from 'react'
import { toast } from "react-toastify";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'

const CreateAccount = (props) => {
    const [formdata, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password2: ''
    })

    //handle the change
    const handleChange = ce => {
        //make a copy of the data
        const copy_state = { ...formdata }
        // manipulate the copy
        copy_state[ce.target.id] = ce.target.value
        // update the actual formdata using the copy
        setFormData(copy_state)
    }


    // signup event
    const signup = async cevent => {
        cevent.preventDefault()
        //reading the data from the state
        //validate the data
        const { validationStatus, validationMessage } = validate(formdata)

        if (validationStatus === true) {
            try {
                //const response =
                const response = await fetch('https://choptyme-fe.onrender.com/user/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formdata)
                })
                //  console.log(response)
                //const data = await response.json()
                if (response.status === 204) {
                    toast.info('User exits already, kindly proceed log in');
                }
                else if (response.status === 201) {
                    toast.success('Registration successful!, Please wait while you are logged in')
                    setTimeout(() => {
                        getLoggedIn(formdata.firstname)
                    }, 5000);
                }
            } catch (error) {
                toast.error('Sorry! something went wrong, Please TRY AGAIN! or Register')
            }
        }
        else {
            alert(validationMessage)
        }
    }






    return (
        <>
            <Container>
                <Form onSubmit={signup} >
                    <h5 className='Super1'>Please fill in your registration details</h5>
                    <Form.Group className="mb-1 input-control">
                        <Form.Label htmlFor="firstname" className="form-label">First Name</Form.Label>
                        <Form.Control type="text" className="form-control" value={formdata.firstname} onChange={handleChange} autoComplete='no' id="firstname" placeholder="Enter your first name" />
                    </Form.Group>

                    <Form.Group className="mb-1 input-control" >
                        <Form.Label htmlFor="lastname" className="form-label">Last Name</Form.Label>
                        <Form.Control type="text" className="form-control" value={formdata.lastname} onChange={handleChange} autoComplete='no' id="lastname" placeholder="Enter your first name" />
                    </Form.Group>


                    <Form.Group className="mb-1 input-control">
                        <Form.Label htmlFor="email" className="form-label">Email address</Form.Label>
                        <Form.Control type="email" className="form-control" value={formdata.email} onChange={handleChange} id="email" autoComplete='Yes' placeholder="Enter your email" aria-describedby="emailHelp" />
                    </Form.Group>

                    <Form.Group className="mb-1 input-control">
                        <Form.Label htmlFor="password" className="form-label">Password</Form.Label>
                        <Form.Control type="password" className="form-control" value={formdata.password} onChange={handleChange} autoComplete='Yes' placeholder="Enter password" id="password" />
                        <div className='error'></div>
                    </Form.Group>

                    <Form.Group className="mb-1 input-control">
                        <Form.Label htmlFor="password2" className="form-label">Repeat Password</Form.Label>
                        <Form.Control type="password" className="form-control" value={formdata.password2} onChange={handleChange} autoComplete='Yes' placeholder="Repeat password" id="password2" />
                    </Form.Group>


                    <Button type="submit" className="btn btn-primary" id="submit">Register</Button>
                    <div className="Path"></div>
                </Form>


                <div>
                    <span className='text-dark ms-5'>Already have an account?<span style={{ color: 'darkred', cursor: 'pointer' }} data-bs-toggle="modal"
                        data-bs-target="#OpenloginPg"> Login</span></span>
                </div>
            </Container>


            <Modal modalid="OpenloginPg" modaltitle="Login"> <Login /> </Modal>

        </>
    )
}

export default CreateAccount

/*
        //message state
        const [message,setMessage]=useState([''])
    
        
    // signup event
        const signup = async cevent => {
            cevent.preventDefault()
            //reading the data from the state
            //validate the data
            const { validationStatus, validationMessage } = validate(formdata)
            
            if (validationStatus === true) {
                try {
                    //const response =
                       const response = await fetch('http://localhost:8000/user/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(formdata)
                    })
                    console.log(response)
                    //const data = await response.json()
                    if (response.status === 204) {
                        setMessage('User exits already, kindly proceed log in');
                    setTimeout(() => {
                      window.addEventListener("focusin",setMessage('')) 
                    }, 2000);  
                    }
                    else if (response.status === 201) {
                    setMessage('Registration successful!, Please wait while you are logged in')
                    setTimeout(() => {
                        getLoggedIn(formdata.firstname)
                    }, 5000);
                    }
                } catch (error) {
                    setMessage('Sorry! something went wrong, Please TRY AGAIN! or Register')
                }
            }
             else {
                alert(validationMessage)
            }
        }
    
    
    
    
    
    
    // signup event     
    const signup = async cevent => {
        cevent.preventDefault()
        if (validateForm()) {
            //put code here
            try {
                const response = await fetch('http://localhost:8000/user/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formdata)
                })
                //  console.log(response)
                //const data = await response.json()
                if (response.status === 204) {
                    alert('User exits already, kindly proceed to log in');
                    //   setTimeout(() => {
                    //           var submitError = document.getElementById('submit-error')
                    //       window.addEventListener("click", submitError.innerHTML ='Please fix error to submit')
                    //   }, 2000);
                }
                else if (response.status === 201) {
                    alert('Registration successful!, Please wait while you are logged in')
                    setTimeout(() => {
                        getLoggedIn(formdata.firstname)
                    }, 5000);
                }
            } catch (error) {
                alert('Sorry! something went wrong, Please TRY AGAIN! or Register')
            }
        }
        // console.log("validated")

        else {
            console.log('not validated')
        }
    }


    // VALIDATE USER INPUT

    // Get the fields from the html
    const fnameError = document.getElementById('firstname-error')
    const lnameError = document.getElementById('lastname-error');
    const emailError = document.getElementById('email-error');
    const passwordError = document.getElementById('password-error');
    const password2Error = document.getElementById('password2-error');



      function validateFirstname() {
        var name = document.getElementById('firstname').value;

        if (name.length === 0) {
          fnameError.dangerouslySetInnerHTML = "Name is required"
          return false;
        }
        // add a check when it returns true
        fnameError.dangerouslySetInnerHTML = '<i class="fas fa-check-circle">x</i>';
        return true
      }

      function validateLastname() {
        var lname = document.getElementById('lastname').value;

        if (lname.length === 0) {
          lnameError.dangerouslySetInnerHTML = "Name is required"
          return false;
        }
        // add a check when it returns true
        lnameError.dangerouslySetInnerHTML = '<i class="fas fa-check-circle">x</i>';
        return true
      }

      function validateEmail() {
        var email = document.getElementById('cemail').value;

        if (email.length === 0) {
          emailError.dangerouslySetInnerHTML = 'Email is required'
          return false
        }

        if (!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          emailError.dangerouslySetInnerHTML = 'Email is invalid'
          return false
        }
        emailError.dangerouslySetInnerHTML = '<i class="fas fa-check-circle">x</i>';
        return true;
      }

function validatePassword(){
    var password = document.getElementById('cpassword').value;

    if(password === '') {
        passwordError.dangerouslySetInnerHTML(password, 'Password is required');
    } else if (password.length < 8 ) {
        passwordError.dangerouslySetInnerHTML(password, 'Password must be at least 8 character.')
    } else {
        passwordError.dangerouslySetInnerHTML = '<i class="fas fa-check-circle">x</i>';
        return true
    }
}

function validatePassword2() {
    var password2 = document.getElementById('password2').value;
    var password = document.getElementById('password').value;

    if(password2 === '') {
        password2Error.dangerouslySetInnerHTML(password2, 'Please confirm your password');
    } else if (password2 !== password) {
        password2Error.dangerouslySetInnerHTML(password2, "Passwords doesn't match");
    } else {
        password2Error.dangerouslySetInnerHTML = '<i class="fas fa-check-circle">x</i>';
        return true
    }

}


      function validateForm() {
        if (!validateFirstname() || !validateLastname() || !validateEmail() || !validatePassword() || !validatePassword2()) {
          toast.error('Please fix error to submit')
          
          return false
        } else {
          alert('validated successfully')
        }
      }
        */

