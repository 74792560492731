import React from 'react'
import Banner from '../components/Banner'
import QuickSearch from '../components/QuickSearch'

const Homepage = () => {
    return (
        <>
            <Banner />
            <QuickSearch/>
        </>
    )
}

export default Homepage