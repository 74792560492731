import Axios from 'axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from '../components/Modal';
import RestaurantMenu from '../components/RestaurantMenu';
import Navbar from '../components/Navbar';

export default function CartPage(props) {
  const navigate = useNavigate();

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;


  const updateCartHandler = async (item, quantity) => {
    const { data } = await Axios.get(`https://choptyme-be.onrender.com/item/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({ type: 'CART_ADD_ITEM', payload: { ...item, quantity } });
  };

  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    if (!localStorage.getItem('currentLoggedinUser')) {
      navigate('/login?redirect=/shipping');
    } else {
      navigate('/shipping');
      window.location.reload()
    }

  };

  return (
    <div>
      <Navbar/>
      <h1>Shopping Cart  <button className="btn btn-danger ms-5" data-bs-toggle="modal" data-bs-target="#OpenResMenu">Add Another Dish</button>
      </h1>

      <Modal modalid="OpenResMenu" modaltitle={`Our Menu`}>
        <RestaurantMenu  />
      </Modal>


      <Row>
        <Col md={8}>
          {cartItems.length === 0 ? (
            <MessageBox>
              Cart is empty. <button className='btn  text-blue' data-bs-toggle="modal" data-bs-target="#OpenResMenu"  ><u>Checkout Our Menu</u></button>
            </MessageBox>
          ) : (
            <ListGroup>
              {cartItems.map((item) => (
                <ListGroup.Item key={item._id}>
                  <Row className="align-items-center">
                    <Col md={4}>

                      <img
                        src={item.image}
                        alt='dishpic'
                        className="img-fluid rounded"
                        style={{ height: '80px', width: '5rem' }}
                      ></img>{' '}
                      {item.name}
                    </Col>
                    <Col md={3}>
                      <Button
                        variant="light"
                        onClick={() => updateCartHandler(item, item.quantity - 1)}
                        disabled={item.quantity === 1}
                      >
                        <i className="fa fa-minus-circle"></i>
                      </Button>{' '}

                      <span>{item.quantity}</span>{' '}

                      <Button
                        variant="light"
                        disabled={item.quantity === item.countInStock}
                        onClick={() => updateCartHandler(item, item.quantity + 1)}
                      >  <i className="fa fa-plus-circle "></i> </Button>
                    </Col>
                    <Col md={3}> <i>&cent;{item.price} x {item.quantity}</i>{' = '} &cent;<b>{item.price * item.quantity}</b> </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeItemHandler(item)}
                      >
                        <i className="fa fa-trash" ></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>
                    Subtotal ({cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                    items) : &cent;
                    {cartItems.reduce((a, c) => a + c.price * c.quantity, 0)}
                  </h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-grid">
                    <Button
                      type="button"
                      onClick={checkoutHandler}
                      variant="primary"
                      disabled={cartItems.length === 0}
                    >
                      Proceed to Checkout
                    </Button>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
