import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';


function ForgottenPassword() {
    useEffect(
        () => {
            document.title = 'Forgotten Password';
        }, []
    )

    const handleSubmit = (e) => {
        e.preventDefault()
    }




    return (
        <div className='container w-50 p-5' >
            <Form onSubmit={handleSubmit}>
                <Form.Group className='fs-1 fw-bold text-center'>
                    <p>FORGOTTEN PASSWORD</p>

                </Form.Group>
                <FormGroup >
                    <Form.Control type="text" placeholder="Enter your email" name="email" required />
                    <Button type="submit">Submit</Button>
                    <Button type="button" className="cancelbtn">Cancel</Button>
                </FormGroup>
                <Form.Group >
                    <Link className='text-danger' to="/login">Back to Login</Link>
                </Form.Group>
            </Form>
            <footer className='fixed-bottom text-center text-light bg-dark'><Link to='/'>Go Home</Link> </footer>
        </div>
    )
}

export default ForgottenPassword