import './Restaurantinfo.css'


const Restaurantinfo = (props) => {
    return (

        <>
            <ul className="nav nav-pills mb-3 tabborder" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                        type="button" role="tab" aria-controls="pills-home" aria-selected="true">Overview</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Contact</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="about my-2"><b>About this place</b></div>
                    <div className="cuisine mt-3"><b>Cuisine</b></div>
                    <div className="cuisines mt-2 fs-6"> {(props.resDetailsFromRP.cuisines) ?
                        (props.resDetailsFromRP.cuisines).join(', ')
                        :
                        ''
                    }</div>
                    <div className="cuisine mt-3"><b>Average Cost</b></div>
                    <div className="cuisines mt-2 fs-6"><b>&cent; {props.resDetailsFromRP.costfortwo}</b> for two people (approx.) </div>

                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="cuisine my-5 "><b>Phone Number</b>
                        <div className="text-danger">{props.resDetailsFromRP.contact_number}</div>
                    </div>
                    <div className="cuisine mt-5 my-2"><b>{props.resDetailsFromRP.name}</b></div>
                    <div className="text-muted mt-2">{props.resDetailsFromRP.address} <br />{props.resDetailsFromRP.city}</div>
                </div>
            </div>
        </>
    )
}

export default Restaurantinfo




