import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilterPage from './Pages/FilterPage'
import RestaurantPage from './Pages/RestaurantPage'
import Orders from './Pages/PlaceOrderPage'
import Login from './components/Login'
import UserProfilePage from './Pages/UserProfilePage'
import OrderHistoryPage from './Pages/OrderHistoryPage'
import Shipping from './Pages/ShippingPage'
import Payment from './Pages/PaymentMethodPage'
import ForgottenPassword from './Functions/ForgottenPassword'
import PasswordReset from './Functions/PasswordReset'
import Homepage from './Pages/Homepage';
import PrivateRoute from './components/PrivateRoute'
import MapPage from './Pages/MapPage'
import CreateAccount from './components/CreateAccount';
import OrderDetailsPage from './Pages/OrderDetailsPage.js'
import CartPage from './Pages/CartPage'
import NotFound from './Pages/NotFound';
import AdminRoute from './components/AdminRoute'
import DashboardPage from './Pages/DashboardPage'
import ItemListPage from './Pages/ItemListPage'
import ItemEditPage from './Pages/ItemEditPage'
import OrderListPage from './Pages/OrderListPage'
import UserListPage from './Pages/UserListPage'
import UserEditPage from './Pages/UserEditPage'



function App() {
  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />

      <Routes>
        <Route path='/' exact element={<Homepage />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/register' exact element={<CreateAccount />} />
        <Route path='filters' exact element={<FilterPage />} />

        <Route path='/profile' exact element={
          <PrivateRoute>
            <UserProfilePage />
          </PrivateRoute>
        }
        />

        <Route path='/orderhistory' exact element={
          <PrivateRoute>
            <OrderHistoryPage />
          </PrivateRoute>
        } />
        <Route path="/orders/order/:id" exact element={
          <PrivateRoute>
            <OrderDetailsPage />
          </PrivateRoute>
        }
        ></Route>
        <Route path='/restaurant/:id' exact element={<RestaurantPage />} />
        <Route path='/payment' exact element={<Payment />} />
        <Route path='/placeorder' exact element={<Orders />} />
        <Route path='/shipping' exact element={<Shipping />} />
        <Route path="/cart" exact element={<CartPage />}></Route>
        <Route path="/cart/:id" exact element={<CartPage />}></Route>

        <Route path="/product/:id/edit" element={<ItemEditPage />}></Route>

        <Route path="/map" exact element={
          <PrivateRoute>
            <MapPage />
          </PrivateRoute>} />
        <Route path='/forgottenpassword' exact element={<ForgottenPassword />} />
        <Route path='/resetpassword/:email' exact element={<PasswordReset />} />

        <Route path='/pagenotfound' element={<NotFound />} />

        <Route path="/admin/dashboard" element={<AdminRoute>
          <DashboardPage />
        </AdminRoute>
        } />

        <Route path="/admin/itemlist" element={
          <AdminRoute>
            <ItemListPage />
          </AdminRoute>
        }
        />

        <Route
          path="/admin/orderlist"
          element={
            <AdminRoute>
              <OrderListPage />
            </AdminRoute>
          }
        />

        <Route
          path="/admin/userlist"
          element={
            <AdminRoute>
              <UserListPage />
            </AdminRoute>
          }
        />

        <Route path="/user/:id/edit"
          element={
            <AdminRoute>
              <UserEditPage />
            </AdminRoute>
          }
        />



      </Routes>

    </>
  )
}

export default App;

