import './QuickSearch.css'
import {useNavigate} from 'react-router-dom'

const QuickSearch = (props) => {
  // use the useNavigate hook
  const navigate= useNavigate()

  // code to navigate on click
  const takeMeToFilterPage = mealtype => navigate(`filters?mealtype=${mealtype}`)
  

    return (
        <div className="container mb-20">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="heading">Quick Searches</h2>
            <h4 className="subheading">Discover restaurants by type of meal</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Breakfast')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img src="../assets/images/Quick Search/breakfast.png" className="img-fluid rounded-start"  alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Breakfast</h5>
                    <p className="card-text">Start your day with our breakfast options</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Lunch')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img src="../assets/images/Quick Search/lunch.png" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Lunch</h5>
                    <p className="card-text">Start your day with exclusive lunch options</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-4">
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Dinner')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img src="../assets/images/Quick Search/dinner.png" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Dinner</h5>
                    <p className="card-text">Start your day with exclusive dinner options</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    

        <div className="row">
          <div className="col-md-4">
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Snacks')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img  src="../assets/images/Quick Search/snacks.png" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Snacks</h5>
                    <p className="card-text">Start your day with exclusive snacks options</p>
                  </div>
                </div>
              </div>
            </div>     
              </div>
          <div className="col-md-4">
            
    
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Drinks')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img src="../assets/images/Quick Search/drinks.png" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Drinks</h5>
                    <p className="card-text">Start your day with exclusive drinks options</p>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
          <div className="col-md-4">
   
            <div className="card mb-3" style={{ "maxWidth": "540px" }} onClick={e => takeMeToFilterPage('Nightlife')}>
              <div className="row g-0">
                <div className="col-md-4 fill">
                  <img src="../assets/images/Quick Search/nightlife.png" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title heading2">Nightlife</h5>
                    <p className="card-text">Enjoy our exclusive night life options</p>
                  </div>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
}

export default QuickSearch 