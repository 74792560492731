// import other components that will be used on this page
import Navbar from '../components/Navbar'
import Pagination from '../components/Pagination'
import Filters from '../components/Filters'
import FilterResults from '../components/FilterResults'

// import methods to be used from packages installed
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
 

    /* Before coming to this filterpage, the user has already been to the homepage and 
    has choosen a card from the QuickSearch which contained a mealtype.
    So in this code, we will be getting the mealtType selected by the user from the url.
    The format will be "?mealTypes=drinks". Since we only need the value, we will use the JS Split method to 
    scope the value after the equal sign (=) */

export const getMealType = (urlPortion) => {     //we declare a var which takes a parameter an returns a
        return urlPortion.split('=')[1]             // .split on it and scoop the value with index 1  
    }

const FilterPage = props => {
     
    const myLocation = useLocation()        // REACT NOTE: USELOCATION() is a react hook that returns the location object used by the react-router 
    const mealtypes = getMealType(myLocation.search)    // JS NOTE: MYLOCATION.SEARCH returns the query string part

     
/* From the Homepage (Banner.js) we had saved the currentCity chosen by the user onto LocalStorage
    Here we will get that item from Local storage to use it on our Filter page heading */
 const currCity = localStorage.getItem('currentCity') 


  /* We use the React usestate hook to store all the filter related data 
  I have passed some default object settgin pgNumber to 1 and OrderSort to 1 (Asc) */
    const [filterData, setFilterData] = useState({
        city: currCity,
        pg:1,
        mealtypes,
        order: 1
    })

   // console.log('from FilterPage',filterData)
    return (
      /* The filter page is made up of the Navbar, Page Heading, Filters, Filter Results and Pagination */
      <>
            <div className="container-fluid">
                <Navbar />
            </div>
            <div className="container" maxwidth='100'>
                <div className="row">
                    <div className="col-md-12 heading">
                        {/* Since the heading is reactive based on user input, i have passed mealtypes & city as var data */}
                        {mealtypes} Places in {currCity}
                    </div>
                </div>

                <div className="row">
                       <Filters setFilterData={setFilterData} filterData={filterData} />                  
                       <FilterResults filterData={filterData} />                   
                </div>

                <div className="row">
                    {/* Since pagination is also a filter data, we pass the filtered state as a prop to it
                                    Because we will need to recieve the filter choice from the user, we also pass the setState to it*/}
                    <Pagination setFilterData={setFilterData} filterData={filterData} />
                </div>
            </div>
            <div className='btmspace'></div>
      </>
    );
}

export default FilterPage


