/* eslint-disable */

   /* This file is used to validate formdata (user input) used for LOGGING IN and SIGNING UP
hence this isnt a component but a function */

function validate ({email,  password }) {   // we are taking out the email & password field from the formdata object sent by user
    // declaring and initialising variables
    let validationStatus = true        // we declare a variable to handle status which is by default set to false.
    let validationMessage = ''          // and Validation message to a default empty string
       
    //email validation benchmark
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    // In validating the email, we use the Regex.test method which returns a boolean value which checks the presence of emailRegex 
    if (!emailRegex.test(email)) {
        //if the validation fails
        validationStatus = false
        validationMessage = 'Your email is incorrect'
    }

    //validate password
    if (password.length < 7) {
        //if the validation fails
        validationStatus = false
        validationMessage += ' Your password must to be atleast 8 characters'
    } 

    return { validationStatus, validationMessage }
}
export default validate


