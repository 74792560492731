const Modal = (props) => {
    return (
        <>
            <div className="modal fade"  id={props.modalid} tabIndex="-1" data-bs-backdrop="static"
                data-bs-keyboard="false" aria-labelledby={`lbl${props.modalid}`} aria-hidden="true">

                <div className="modal-dialog">
                    <div className="modal-content" style={{ backgroundColor: 'whitesmoke' }}>
                        {/* <div className="modal-header">
                            <h5 className="modal-title"
                                id={`lbl${props.modalid}`} >{props.modaltitle}</h5>
                        </div> */}

                        <div className="modal-body">
                            <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>

                            {props.children ? props.children : 'Body here'}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal