import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function ResetPassword() {
    useEffect(
        () => {
            document.title = 'Reset Password';
        }, []
    )
    return (
        <div>
            <form action="action_page.php" method="post">
                <div className="imgcontainer">
                    <p>PASSWORD RESET</p>
                </div>
                <div className="container">
                    <label htmlFor="psw">Password</label>
                    <input type="password" placeholder="Enter new password" name="psw" required />

                    <label htmlFor="cnfmpsw">Confirm Password</label>
                    <input type="password" placeholder="confirm new password" name="cnfmpsw" required />

                    <button type="submit">Submit</button>

                </div>
                <div className="container">
                    <button type="button" className="cancelbtn">Cancel</button>
                    <span className="psw">Back to <Link to="/login">Login</Link></span>
                </div>
            </form>


            <footer className='fixed-bottom text-center text-light bg-dark'><Link to='/'>Go Home</Link> </footer>

        </div>
    )
}

export default ResetPassword