import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CheckoutSteps from '../components/CheckoutSteps';
import Navbar from '../components/Navbar';
import { Store } from '../Store';

export default function PaymentMethodPage() {
  const navigate = useNavigate();


  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { shippingAddress, paymentMethod },
  } = state;
  if (!shippingAddress.address) {
    navigate('/shipping');
  }

  const [paymentMethodName, setPaymentMethod] = useState(
    paymentMethod || 'PayPal'
  );

  const submitHandler = (e) => {
    e.preventDefault();
    ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: paymentMethodName });
    localStorage.setItem('paymentMethod', paymentMethodName);
    navigate('/placeorder');
  };





  return (
    <div>
      <Navbar />
      <br />
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <div className="container small-container">
        <h1 className="my-3">Payment Method</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="PayPal"
              label="PayPal"
              value="PayPal"
              checked={paymentMethodName === 'PayPal'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Form.Group>
          <div className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="Stripe"
              value="Stripe"
              checked={paymentMethodName === 'Stripe'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </div>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="PayTM"
              value="PayTM"
              checked={paymentMethodName === 'PayTM'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="Bitcoin"
              value="Bitcoin"
              checked={paymentMethodName === 'Bitcoin'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="Paystack"
              value="Paystack"
              checked={paymentMethodName === 'Paystack'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="Flutterwave"
              value="Flutterwave"
              checked={paymentMethodName === 'Flutterwave'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit"> Continue </Button >
        </Form>
      </div>
    </div>
  );
}
