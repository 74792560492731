// import my css
import './Navbar.css'

// import components needed for use on this page
import Modal from './Modal'
import Login from './Login'
import CreateAccount from './CreateAccount'

// import methods to be used from packages installed
import { getLoggedOut } from './utilities'
import { useNavigate } from 'react-router-dom'
//import { LinkContainer, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Store } from '../Store';
import { useContext } from 'react'



const Navbar = () => {

    /* From the Banner component, we saved the loggedin user to Local Storage
        Here in this file, we will get that data for use */
    const activeUser = localStorage.getItem('currentLoggedinUser')

    // This line of code is to use the useNavigate action we imported from react-router-dom
    const navigate = useNavigate()

    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { userInfo } = state;

    const signoutHandler = () => {
        ctxDispatch({ type: 'USER_SIGNOUT' });
        localStorage.removeItem('userInfo');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('shippingAddress');
        localStorage.removeItem('paymentMethod');
        window.location.href = '/login';
        getLoggedOut()
        localStorage.clear()
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light" style={{ "backgroundColor": "#eb2929" }}>
            <div className="container-fluid">
                {/*I have added a navigate function here to take the user back to the homepage on click of the logo */}
                <a className="navbar-brand" onClick={() => navigate(-1)} href='/#' >
                    {/* <img src="./logo.png" alt="" /> */}
                    <label className="elogo">CHOPTIME</label>
                </a>
                <button className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {/*This is a ternary code that shows a logout button when a user login instead on a create account */}
                        {userInfo && userInfo.isAdmin && (
                                <div className="dropdown">
                                    <button type="button" className="btn btn-outline-light dropdown-toggle" data-bs-toggle="dropdown"> Admin</button>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to='/admin/dashboard'>Dashboard</Link></li>
                                        <li><Link className="dropdown-item" to="/admin/itemlist">Items</Link></li>
                                        <li><Link className="dropdown-item" to="/admin/orderlist">Orders</Link></li>
                                        <li><Link className="dropdown-item" to="/admin/userlist">Users</Link></li>
                                    </ul>
                                </div>
                        )}
                        &nbsp;
                        {activeUser ?
                            <>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-outline-light dropdown-toggle" data-bs-toggle="dropdown"> Hello! {activeUser}</button>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to='/profile'>User Profile</Link></li>
                                        <li><Link className="dropdown-item" to="/orderhistory">Order History</Link></li>
                                        <li className="dropdown-divider" ></li>
                                        <li><button className='btn '
                                            // onClick={getLoggedOut}
                                            to="#signout"
                                            onClick={signoutHandler}>Logout</button></li>
                                    </ul>
                                </div>


                                <Link className="navbar-brand" to="/profile">
                                    <img className="rounded-pill avatar" src="./assets/images/avatar.png" alt="" style={{ width: '40px' }} />

                                </Link>
                            </>
                            :
                            <>
                                <button className="btn btn-outline-light"
                                    data-bs-toggle="modal"
                                    data-bs-target="#OpenloginPg">Login</button> &nbsp;

                                <button className="btn btn-outline-light"
                                    data-bs-toggle="modal"
                                    data-bs-target="#OpencreateaccPg">Create an account</button>
                            </>}
                    </ul>

                </div>
            </div>
            {/* We are using the modal component to display both the login and signup forms */}
            <Modal modalid="OpenloginPg" modaltitle="Login">
                <Login />
            </Modal>

            <Modal modalid="OpencreateaccPg" modaltitle="Create Account">
                <CreateAccount />
            </Modal>
        </nav>
    )
}

export default Navbar