import './RestaurantMenu.css'
import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { getError } from '../utils';
import Badge from 'react-bootstrap/Badge'

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return { ...state, menuItems: action.payload, loading: false, };
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const RestaurantMenu = (props) => {

    const resname = localStorage.getItem('CurrentRes')
    const navigate = useNavigate();

    const [{ loading, error, menuItems}, dispatch] = useReducer(reducer,
        {
            menuItems: [],
            loading: true,
            error: '',
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'FETCH_REQUEST' });
            try {
                const { data } = await axios.get(`https://choptyme-be.onrender.com/menu/${resname}`);
                dispatch({ type: 'FETCH_SUCCESS', payload: data});
            } catch (err) {
                dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
            }
        };
        fetchData();
    }, [resname]);


    const { state, dispatch: ctxDispatch } = useContext(Store);
    const {
        cart: { cartItems },
    } = state;

    const addToCart = async (item) => {
       // console.log('idAdded', item)
       // console.log('idAdded----ID', item._id)

        const existItem = cartItems.find((x) => x._id === item._id)
        const quantity = existItem ? existItem.quantity + 1 : 1;

        const { data } = await axios.get(`https://choptyme-be.onrender.com/item/${item._id}`)
          if (data.countInStock < quantity) {
              window.alert('Sorry. Dish is out of stock');
              return;
          }
          ctxDispatch({ type: 'CART_ADD_ITEM', payload: { ...item, quantity } })
          navigate('/cart');
        window.location.reload()
    }
  //  console.log('menuItems',menuItems)


    return (
        <div className="row">
            {loading ? (
                <LoadingBox />
            )
                : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                ) :
                    (
                        <div className="row">
                            <ul type='circle'>
                                {
                                    menuItems.map((dish, index) =>

                                        <li className='border-bottom mb-2' key={dish._id}>
                                            <div className="row" >
                                                <div className="col-6 text-wrap">
                                                    <div className="text-success fs-6"> VEG? {dish.isveg ? 'Yes' : 'No'} </div>
                                                    <div className="cuisines">{dish.name}</div>
                                                    <div className="cuisines1">&cent; {dish.price}</div>
                                                </div>
                                                <div className="col-3 m-0"><img style={{ width: '5rem', height: '80px', marginRight: '1rem', marginTop: '1rem' }} src={dish.image} alt={dish.name} /> </div>
                                                <div className="col-3 d-flex align-items-center">
                                                    {dish.countInStock > 0 ? (
                                                        <button className="btn btn-success btn-sm text-nowrap" onClick={() =>  addToCart(dish) }>Add to Cart</button>
                                                    ) : (
                                                        <Badge bg="danger">Out of Stock</Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    )}
            <div className="row mb-2 text-center fs-5">
                <hr />
                <b>NOTE:</b><span style={{ color: 'red' }}> Delivery fee of &cent; 10, applies if your order worth is below &cent; 100</span>
            </div>


        </div>

    )
}

export default RestaurantMenu