import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"

import Carousel from "../components/Carousel"
import RestaurantInfo from "../components/Restaurantinfo"
import RestaurantMenu from '../components/RestaurantMenu'
import Modal from '../components/Modal'
import Navbar from '../components/Navbar'

const RestaurantPage = props => {

    // get the id from the url
    const { id } = useParams()

    const [restaurantsDetails, setRestaurantsDetails] = useState({})

    localStorage.setItem('CurrentRes',restaurantsDetails.name)

    // get the restaurant details received on first load.
    useEffect(() => {
        fetch(`https://choptyme-be.onrender.com/restaurant/${id}`)
            .then(resp => resp.json())
            .then(resp => setRestaurantsDetails(resp))
          //  .then(resp => setRestaurantsDetails(resp[0]))
            .catch(err => console.log(err))
    }, [id])

    useEffect(() => {
    }
    )

    // getRestaurantBasedonParam()
    return (
        <>
            <div className="container-fluid">
                <Navbar />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Carousel images={restaurantsDetails.images} />
                    </div>
                </div>
                <h2>{restaurantsDetails.name}</h2>
                <div className="row">
                    <div className="col-md-2 offset-md-10">
                        <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#OpenResMenu">Our Menu</button>

                        <Modal modalid="OpenResMenu" modaltitle={`Our Menu - ${restaurantsDetails.name}`}>
                            <RestaurantMenu Details={restaurantsDetails} />
                        </Modal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <RestaurantInfo resDetailsFromRP={restaurantsDetails} />
                    </div>
                </div>
            </div>
            <div className='btmspace'></div>
        </>
    )
}

export default RestaurantPage