import '../components/UserProfilePage.css'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import Navbar from '../components/Navbar'
import Axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };

    default:
      return state;
  }
};

const UserProfile = (props) => {

  const navigate = useNavigate();

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, cart: { shippingAddress, paymentMethod }, } = state;

  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });


  //console.log(props)
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await Axios.get(`https://choptyme-be.onrender.com/getuser/${userInfo.email}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setEmail(data.email);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(error),
        });
      }
    };
    fetchData();
  }, [dispatch, userInfo]);


  const profsubmitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_REQUEST' });

    try {
      if (password.length === 0) {
        toast.error('Enter a new or old Password to confirm');
      } else if (password !== confirmPassword) {
        toast.error('Passwords do not match');
      }

      else {
        const { data } = await Axios.patch(
          'https://choptyme-be.onrender.com/user/profile',
          {
            firstname,
            lastname,
            email,
            password,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({
          type: 'UPDATE_SUCCESS',
        });

        ctxDispatch({ type: 'USER_SIGNIN', payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
        toast.success('User updated successfully');
      }
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };

  /***************************************SHIPPING SIDE************************ */


  // set default to shipping detail from state saved in LS
  const [fullName, setFullName] = useState(shippingAddress.fullName || '');
  const [address, setAddress] = useState(shippingAddress.address || '');
  const [city, setCity] = useState(shippingAddress.city || '');
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode || '');
  const [country, setCountry] = useState(shippingAddress.country || '');

  const shipsubmitHandler = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: 'SAVE_SHIPPING_ADDRESS',
      payload: {
        fullName,
        address,
        city,
        postalCode,
        country,
        location: shippingAddress.location,
      },
    });

    localStorage.setItem('shippingAddress', JSON.stringify({ fullName, address, city, postalCode, country, location: shippingAddress.location, }));
    navigate('/payment');
  };

  /*********************************PAYMENT METHOD ************************* */
  const [paymentMethodName, setPaymentMethod] = useState(
    paymentMethod || 'PayPal'
  );

  const paysubmitHandler = e => {
    e.preventDefault();
    ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: paymentMethodName });
    localStorage.setItem('paymentMethod', paymentMethodName);
  }


  const [file, setFile] = useState()

  function handleChange(e) {
    //   console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    
  }


  return (
    <>
      <div className='d-flex flex-column mb-4 site-container'>
        <Navbar />
        <h3 className="my-3 text-center">User Profile & Settings</h3>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Row className='border justify-content-around'>
            <Col md={4} className='border-end'>
              <ListGroup>
                <ListGroup.Item>
                  <a href='/orderhistory'>My Orders</a>
                </ListGroup.Item>

                <ListGroup.Item>

                  <p className='fw-bold text-center'>My Shipping Details</p>

                  <Form onSubmit={shipsubmitHandler}>
                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control value={address} onChange={(e) => setAddress(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>City</Form.Label>
                      <Form.Control value={city} onChange={(e) => setCity(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="postalCode">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        value={country} onChange={(e) => setCountry(e.target.value)} required />
                    </Form.Group>

                    <div className="mb-3">
                      <Button
                        id="chooseOnMap"
                        type="button"
                        variant="light"
                        onClick={() => navigate('/map')}
                      >
                        Choose Location On Map
                      </Button>
                      {shippingAddress.location && shippingAddress.location.lat ? (
                        <div>
                          LAT: {shippingAddress.location.lat}
                          LNG:{shippingAddress.location.lng}
                        </div>
                      ) : (
                        <div>No location</div>
                      )}
                    </div>
                    <div className="mb-3">
                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </div>
                  </Form>

                </ListGroup.Item>

              </ListGroup>
            </Col>
            <Col md={3} className='border-end'>
              <Card>
                <Card.Body>
                  <Card.Title className='text-center'>My Avatar</Card.Title>
                  <Card.Text>Upload a new Avatar</Card.Text>
                        {userInfo.isProfilePicSet === false || !userInfo.isProfilePicSet ? 

                          (
                            <div>
                         <img src='./assets/images/avatar.png' alt='imgAvatar' width={300} className="mb-3" />
                        <input type="file" onChange={handleChange} />
                  </div>
                          ): (
                      <div>
                         <img src={file} alt='imgAvatar' width={300} className="mb-3" />
                        <input type="file" onChange={handleChange} />
                      </div>
                          )
}




                        

                </Card.Body>
              </Card>

              <div>

                <div className="container small-container">

                  <p className="my-3 fw-bold text-center">My Preffered Payment Method</p>
                  <form onSubmit={paysubmitHandler}>
                    <div className="mb-3">
                      <Form.Check
                        type="radio"
                        id="PayPal"
                        label="PayPal"
                        value="PayPal"
                        checked={paymentMethodName === 'PayPal'}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="radio"
                        id="Stripe"
                        label="Stripe"
                        value="Stripe"
                        checked={paymentMethodName === 'Stripe'}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="radio"
                        id="Stripe"
                        label="Paytm"
                        value="Paytm"
                        checked={paymentMethodName === 'PayTM'}
                        onChange={(e) => setPaymentMethod(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="radio"
                        id="Stripe"
                        label="Bitcoin"
                        value="Bitcoin"
                        checked={paymentMethodName === 'Bitcoin'}
                        onChange={(e) => setPaymentMethod(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <Button type="submit">Update</Button>
                    </div>
                  </form>
                </div>
              </div>




            </Col>
            <Col md={5} >
              <p className='fw-bold text-center'>My Profile Details</p>
              <Form onSubmit={profsubmitHandler}>
                <Form.Group className="mb-3 " controlId="fname">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <div className="mb-3">
                  <Button type="submit" disabled={loadingUpdate}>
                    Update
                  </Button>
                </div>
                {loadingUpdate && <LoadingBox></LoadingBox>}
              </Form>
            </Col>
          </Row>
        )}
      </div>
      <footer>
        <div className="footer h-23 clearfix text-white" style={{ "backgroundColor": '#34Ff23' }}></div>
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark text-white d-flex justify-content-around">
          <div>© Zomato_Clone All Rights Reserved </div>
          <div>Powered by <a style={{ color: 'yellow' }} href="https://superjcybs.com"> &nbsp; Jerry Babatunde (SUPERJCYBS)</a></div>
          <span style={{ 'backgroundColor': 'white', color: 'blue', fontWeight: 'bold', paddingLeft: '10px', borderRadius: '50%', fontSize: '20px', width: '30px', height: '30px' }}><a href='https://www.facebook.com/superjcybs'>f</a></span>
        </nav>

      </footer>

    </>
  )
}






export default UserProfile
