import { useState, useEffect } from 'react';

import './Filters.css';


const Filters = (props) => {
  
  // clear filters 
  const clrFilter = clrfil => {
   // console.log('clrfil is clicked')
    const clrcopy_state = { ...props.filterData }
     // Deletion of unwanted data, since it will be provided from UI
    delete clrcopy_state.pg
    delete clrcopy_state.order
    delete clrcopy_state.cuisines
    delete clrcopy_state.locality
    delete clrcopy_state.costfortwo   
  //  console.log('from clrfilter2', clrcopy_state)
     props.setFilterData(clrcopy_state)
  }
//console.log(props.filterData)


// create a state to hold the locations
  const [locs, setLocs] = useState([])

  // on the component load
  const getAddressForCity = async () => {
    //get the current city value from the local storage
    const city = localStorage.getItem('currentCity')

    // make an api call 
    const res = await fetch(`https://choptyme-fe.onrender.com/locations/${city}`)
    const locationsData = await res.json()
    // get all the addresses from the q
    const addresses = locationsData.map(i => i.locality)
    //set the state
    setLocs(addresses)
  }

  // on load of the filter component
  useEffect(() => {
    getAddressForCity()
  },[])

  const handleChange = ce => {
    // FOR THE SELECT TYPE
    if (ce.target.type === 'select-one') {
      // creating a copy of the state
      const copy_state = { ...props.filterData }  // where from this props.filterData
      copy_state.locality = ce.target.value
    //  console.log(copy_state)
      props.setFilterData(copy_state)

    } 

    // FOR THE CHECK TYPE
      // check the type 
    if (ce.target.type === 'checkbox') {
      //copy the state
      const copy_state = { ...props.filterData }
    
      if (ce.target.checked) {
        if ('cuisines' in copy_state) {
          copy_state.cuisines.push(ce.target.value)
        } else {
          copy_state.cuisines = [ce.target.value]
        }
      }
      // the person has unticked
      else {
        copy_state.cuisines = copy_state.cuisines.filter(i => i !== ce.target.value)
      }
      // if none is ticked, delete cuisines and give results without cuisines
      if (copy_state.cuisines.length === 0) {
      delete copy_state.cuisines
    }
        //  console.log(copy_state)
      props.setFilterData(copy_state)  // set the final data to state
    }
    
    //check
    if (ce.target.type === 'radio') {
      // console.log('radio button changed!')
      if (ce.target.name === "sort") {
        //sort radio buttons were clicked
        const copy_state = { ...props.filterData }
        copy_state.order = ce.target.value
//        console.log(copy_state)
        props.setFilterData(copy_state)

      } else {
        //cost radio buttons were clicked
        const copy_state = { ...props.filterData }
        copy_state.costfortwo = ce.target.value
        // store value unto local storage
        
     //   localStorage.setItem('order clicked',ce.target.value)
      //  console.log(copy_state)
        props.setFilterData(copy_state)
      }
    }
  }




  return (
    <div className="col-md-3 card">
      <span className="subheading"> Filters &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <button className='btn btn-danger' onClick={clrFilter} style={{visibility:'hidden'}}>CLEAR FILTER</button></span>
      <span className="text-muted"> Select Location</span>
      <select defaultValue="Choose your area" className="form-select" id="dpLocations" aria-label="Default select example" onChange={handleChange} >
        <option disabled>Choose your area</option>
        {locs.map((i,index) => <option key={index} value={i}>{i}</option>)}

      </select>
      <br />

      <span className="text-muted"> Cuisine</span>
      <div className="form-check">
        <input className="form-check-input" type="checkbox"
          value="North Indian" id="chbNorthIndian" onChange={handleChange} />
        <label className="form-check-label" htmlFor="chbNorthIndian">
          North Indian
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="South Indian" id="chbSouthIndian" onChange={handleChange} />
        <label className="form-check-label" htmlFor="chbSouthIndian">
          South Indian
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="Chinese" id="chbChinese" onChange={handleChange} />
        <label className="form-check-label" htmlFor="chbChinese">
          Chinese
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="Fast Food" id="chbFastFood" onChange={handleChange} />
        <label className="form-check-label" htmlFor="chbFastFood">
          Fast Food
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="Drinks" id="chbDrinks" onChange={handleChange} />
        <label className="form-check-label" htmlFor="chbDrinks">
          Drinks
        </label>
      </div>
      <br />

      <span className="text-muted"> Cost for two (In GH&cent;)</span>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="cost" id="rblt0.5" value="0-50" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rblt0.5">
          Less than 50
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="cost" id="rb0.5kto1k" value="51-100" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rb0.5kto1k">
          51 - 100
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="cost" id="rb1kto1.5k" value="101-300" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rb1kto1.5k">
          101 - 300
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="cost" id="rb1.5kto2k" value="301-500" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rb1.5kto2k">
          301 - 500
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="cost" id="rb2k+" value="501-10000" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rb2k+">
          More than 500
        </label>
      </div>
      <span className="subheading"> Sort</span>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="sort" id="rbPriceLowToHigh" value="1" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rbPriceLowToHigh">
          Price - Low to High
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="sort" id="rbPriceHighToLow" value="-1" onChange={handleChange} />
        <label className="form-check-label" htmlFor="rbPriceHighToLow">
          Price - High to Low
        </label>
      </div>
      <br />
    </div>
  )
}

export default Filters;